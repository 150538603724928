/** @jsx jsx */
import { Box, Text, jsx } from "theme-ui"
import { Link as GatsbyLink } from "gatsby"

export const Logo = () => (
  <Box
    sx={{
      a: {
        ":focus": {
          outline: "none",
        },
      },
    }}
  >
    <GatsbyLink to="/">
     <Text variant='styles.h5'>ipalongengi</Text> 
    </GatsbyLink>
  </Box>
)
